import { Flex, Box } from 'theme-ui';
import { DateTime } from 'luxon';
import { useSongsContext } from '../../lib/songs-context';
import { CompositionVersion } from '../../lib/types';

const Item: React.FC<{ version: CompositionVersion }> = ({ version }) => {
  const jsDate = version.createdAt.toDate();
  if (!jsDate) return null;

  const date = DateTime.fromJSDate(jsDate)
    .setLocale('ru-RU')
    .toLocaleString(DateTime.DATE_FULL);

  const dataArr = date.split(' ');

  return (
    <Flex
      sx={{ py: 2, alignItems: ['flex-start', 'center'], flexDirection: 'row' }}
    >
      <Box sx={{ flex: '0 0 100px' }}>
        <Box>
          {dataArr[0]} {dataArr[1]}
        </Box>
        <Box>
          {dataArr[2]} {dataArr[3]}
        </Box>
      </Box>
      <Box
        sx={{
          background: '#e4e4e4',
          borderRadius: '3px',
          color: '#555',
          display: 'inline-block',
          flex: '0 0 25px',
          fontSize: '0.75rem',
          padding: '0.25rem',
          textAlign: 'center',
        }}
      >
        {version.version}
      </Box>
      <Box sx={{ px: 2 }}>
        {version.actionType === 'PUBLISH' ? 'Публикация' : 'Исправления'}
        {version.info ? `: ${version.info}` : ''}
      </Box>
    </Flex>
  );
};

export const Versions: React.FC = () => {
  const {
    song: { versions },
  } = useSongsContext();

  if (!versions?.length) return null;

  versions.sort((a, b) =>
    b.createdAt.toDate() >= a.createdAt.toDate() ? 1 : -1
  );

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors!.grey,
        borderRadius: '5px',
        p: 3,
        mb: 3,
        overflowX: 'auto',
      })}
    >
      <Box>Версии:</Box>
      {versions.map((item) => (
        <Item key={item.versionDocRef} version={item} />
      ))}
    </Box>
  );
};
