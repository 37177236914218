import { useState, useEffect } from 'react';
import { Composition } from '../../lib/types';

import { trackClick } from '../../lib/utils';

import { RegularLink } from '../shared-styled/links';
import { Backdrop, Box, Close, LinksBox, Link } from './styled';

const donationCategories = ['symphonic', 'chamber'];

export const Modal = ({ song }: { song: Composition }) => {
  const [showModal, setShowModal] = useState(false);

  const hideDonationModalFlag = localStorage.getItem('hideDonationModal');
  const shouldShowDonationModal = hideDonationModalFlag !== 'true';

  useEffect(() => {
    const shouldShowModal =
      donationCategories.includes(song.category) &&
      !song.payHipProducts?.length;
    setShowModal(shouldShowModal);
  }, [song.category, song.payHipProducts]);

  const handleEscKey = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, []);

  const hidePermanently = () => {
    localStorage.setItem('hideDonationModal', 'true');
    setShowModal(false);
  };

  return shouldShowDonationModal && showModal ? (
    <>
      <Backdrop onClick={() => setShowModal(false)} />
      <Box>
        <Close onClick={() => setShowModal(false)}>+</Close>
        Друзья, это небезызвестно, что написание оркестровой партитуры занимает
        много времени. Иосиф Никита, отец многодетного семейства, жертвует по
        несколько часов в день, чтобы в наших церквах звучала красивая музыка, —
        и делает это безвозмездно. Пожалуйста, поддержите материально этого
        композитора, пожертвовав любую сумму ради этого труда, «ибо трудящийся
        достоин пропитания». Для этого вы можете использовать ссылку на его
        персональную страницу PayPal:{' '}
        <RegularLink
          href='https://www.paypal.me/IosifNichita'
          target='_blank'
          rel='noopener noreferrer'
          display='inline-block'
          p={0}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            trackClick(
              e,
              'https://www.paypal.me/IosifNichita',
              'donation',
              'links',
              'outbound_page_view'
            )
          }
        >
          paypal.me/IosifNichita
        </RegularLink>
        . Так деньги будут переведены прямо на его счёт. Вы также можете
        поддерживать это служение через сервис Patreon:{' '}
        <RegularLink
          href='http://patreon.com/iosifnichita'
          target='_blank'
          rel='noopener noreferrer'
          display='inline-block'
          p={0}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            trackClick(
              e,
              'http://patreon.com/iosifnichita',
              'patreon',
              'links',
              'outbound_page_view'
            )
          }
        >
          patreon.com/IosifNichita
        </RegularLink>
        <LinksBox>
          <Link onClick={() => hidePermanently()}>Больше не показывать</Link>
          <Link onClick={() => setShowModal(false)}>Закрыть</Link>
        </LinksBox>
      </Box>
    </>
  ) : null;
};
