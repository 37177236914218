import { useCallback } from 'react';
import { Box, Link } from 'theme-ui';
import { useSongsContext } from '../../lib/songs-context';

const formatPrice = (price: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(price));

export const PayHipProducts = () => {
  const { song } = useSongsContext();

  const openPayhip = useCallback((event, id) => {
    // @ts-expect-error
    if (window.Payhip && id) {
      event.preventDefault();
      // @ts-expect-error
      window.Payhip.Checkout.open({
        product: id,
      });
    }
  }, []);

  if (!song?.payHipProducts?.length) return null;

  return (
    <Box pb={3}>
      Купить файлы
      {song.payHipProducts.map(({ id, name, price }) => (
        <Link
          key={id}
          href={`https://payhip.com/b/${id}`}
          className='payhip-buy-button'
          data-theme='none'
          data-product={id}
          target='_blank'
          rel='noopener noreferrer'
          onClick={(e) => openPayhip(e, id)}
          sx={{
            color: '#740429',
            padding: 10,
            background: '#e4e4e4',
            borderRadius: 5,
            fontSize: '1rem',
            textDecoration: 'none',
            display: 'flex',
            marginY: 2,
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span>{name}</span>
          <span>{formatPrice(price)}</span>
        </Link>
      ))}
    </Box>
  );
};
