import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSongsContext } from '../../lib/songs-context';
import { titleSuffix } from '../../lib/utils';
import { Player } from './player';
import { FileViewer } from './pdf';
import { PaidSongInfo } from './paid-song-info';
import { Versions } from './versions';
import { PrevNext } from './prev-next';
import { Modal } from '../modal';
import { Wrapper } from '../shared-styled/layout';
import { RelatedSongs } from './related-songs';
import { Tags } from './tags';
import { categoryMap } from '../../lib/utils';
import { CompositionCategory, FileType } from '../../lib/types';
import { Box, Flex, Text } from 'theme-ui';
import { Info } from './info';
import { Files } from './files';
import { PayHip } from './payhip';
import { PayHipProducts } from './payhip-products';

export const Composition = () => {
  const { song, isLoadingSong, setSongUrl } = useSongsContext();
  const { category, name } = useParams<{
    category: CompositionCategory;
    name: string;
  }>();
  const [currentFile, setCurrentFile] = useState<FileType>({} as any);
  const [thumbnail, setThumbnail] = useState<FileType>({} as any);

  useEffect(() => {
    if (isLoadingSong) {
      setCurrentFile({} as any);
      setThumbnail({} as any);
    }
  }, [isLoadingSong]);

  useEffect(() => {
    if (isLoadingSong || !song?.uploadedFiles?.length) return;
    const jpgFile = song?.uploadedFiles?.find((x) => x.extension === 'jpg');
    if (!jpgFile) return;
    setThumbnail(jpgFile);
  }, [song, isLoadingSong]);

  useEffect(() => {
    if (category && name) {
      setSongUrl(`/${category}/${name}`);
    }

    return () => {
      setSongUrl('');
    };
  }, [category, name, setSongUrl]);

  if (isLoadingSong) return <Wrapper>Загрузка…</Wrapper>;

  if (!category || !name || !song || !song.isVisible)
    return <Wrapper>Error!</Wrapper>;

  return (
    <Wrapper>
      <Helmet>
        <title>
          {`${song.name} | ${categoryMap(
            song.category,
            'long'
          )} | Произведения ${titleSuffix}`}
        </title>
      </Helmet>

      <Box pt={1} />
      <PaidSongInfo />
      <Box pt={2} />

      <Text
        sx={{
          display: ['block', 'block', 'none'],
          fontSize: '1.5rem',
          mb: 3,
          textAlign: 'center',
        }}
      >
        {song.name}
      </Text>

      <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
        {Boolean(thumbnail?.url) && (
          <Box
            sx={{
              display: 'block',
              width: [300, 550],
              height: [300, 550],
              flexBasis: [300, 550],
              mx: 'auto',
              img: {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            }}
          >
            <img src={thumbnail.url} alt={thumbnail.name} />
          </Box>
        )}
        {Boolean(currentFile?.url) && (
          <Box
            sx={{
              display: 'block',
              width: [300, 550],
              flexBasis: [300, 550],
              mx: 'auto',
            }}
          >
            <FileViewer file={currentFile} />
          </Box>
        )}

        <Box sx={{ flex: 1, pl: [0, 0, 3], mt: [3, 3, 0] }}>
          <Info />
          <Player />
          <PayHip />
          <PayHipProducts />
          <Files currentFile={currentFile} setCurrentFile={setCurrentFile} />
          <Versions />
          <RelatedSongs />
          <Tags />
        </Box>
      </Flex>

      <Box pt={4} />
      <PrevNext />

      <Modal song={song} />
    </Wrapper>
  );
};
